import { graphql } from 'gatsby'
import React from 'react'

import { capitalize } from '../../../lib/string'

import Breadcrumb from '../../components/breadcrumb'
import FragrancesList from '../../components/fragrances-list'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export const frontmatter = {
  body: 'Perfumes',
  slug: '/perfumes/',
  title: 'Perfumes',
}

export const query = graphql`
  query FragrancesPageQuery($genderId: PostGraphile_Gender!) {
    ...SiteInfo
    results: postgres {
      fragrances: allFragrancesList(
        condition: { gender: $genderId },
        orderBy: [ BRAND_ASC, NAME_ASC ],
      ) {
        ...Fragrance
        brand: brandByBrand {
          ...Brand
        }
      }
    }
  }
`

export default ({ data }) => {
  const { fragrances } = data.results
  const { gender, links, meta } = data.site.siteMetadata
  const { entity } = meta

  const page = entity.plural
  const title = `${capitalize(gender)}'s ${page}`
  const keywords = [page]
  const trail = [
    {
      name: page,
      slug: links.fragrances,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: title,
    keywords,
    name: title,
    products: fragrances,
    slug: links.fragrances,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={title} />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      <FragrancesList fragrances={fragrances} />

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
